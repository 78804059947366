<template>
  <div>
    <el-row :gutter="24" class="d-flex-align-center">
      <el-col :span="12">
        <base-input
          :key="getNameValue +'key'"
          :label="$t('general.label.name_label')"
          required
          readonly
          :validations="validations.form.nameLabel.defaultValue"
          :value="getNameValue"
          placeholder="service"
        />
      </el-col>
      <el-col :span="12">
        <base-button :label="$t('general.label.add')" type="primary" @click="toggleShowModalName" />
      </el-col>
      <add-labels-modal
        v-if="isShowModalName"
        :form="form.nameLabel"
        :titel="title"
        :closeModal="toggleShowModalName"
        :selectLabel="changeExistLabelName"
      />
    </el-row>
    <el-row :gutter="24" class="d-flex-align-center">
      <el-col :span="12">
        <base-input
          :key="getInfoValue +'key'"
          :label="$t('general.label.name_info_label')"
          readonly
          :value="getInfoValue"
          placeholder="service"
        />
      </el-col>
      <el-col :span="12">
        <base-button :label="$t('general.label.add')" type="primary" @click="toggleShowModalInfo" />
      </el-col>
      <add-labels-modal
        v-if="isShowModalInfo"
        :form="form.infotextLabel"
        :titel="title"
        :closeModal="toggleShowModalInfo"
        :selectLabel="changeExistLabelInfo"
      />
    </el-row>
  </div>
</template>

<script>
import AddLabelsModal from './AddLabelsModal'
export default {
  name: 'AddLabelInput',
  props: {
    title: {
      default: 'New label'
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    changeExistLabelName: {
      type: Function,
      default: () => {}
    },
    changeExistLabelInfo: {
      type: Function,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => ({
        nameLabel: {
          defaultValue: '',
          labelTranslations: []
        },
        infotextLabel: {
          defaultValue: '',
          labelTranslations: []
        }
      })
    }
  },
  components: {
    AddLabelsModal
  },
  data () {
    return {
      isShowModalName: false,
      isShowModalInfo: false
    }
  },
  computed: {
    getInfoValue () {
      if (this.form.infotextLabel !== null) {
        return this.form.infotextLabel.defaultValue
      }
      return this.form.infotextLabel
    },
    getNameValue () {
      if (this.form.nameLabel !== null) {
        return this.form.nameLabel.defaultValue
      }
      return this.form.nameLabel
    }
  },
  methods: {
    toggleShowModalName () {
      this.isShowModalName = !this.isShowModalName
    },
    toggleShowModalInfo () {
      this.isShowModalInfo = !this.isShowModalInfo
    }
  },
  mounted () {}
}
</script>

<style lang='scss'>
.d-flex-align-center {
  display: flex;
  align-items: center;
  & button{
        top: 6px;
  }
}
</style>
