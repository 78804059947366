<template>
  <view-resource
    :tableData="tableData"
    :pagination="pagination"
    @deactivate="deactivateService"
    @activate="activateService"
    @fetchResources="fetchData"
    :loading="loading"
    @changeMrp="resetPagination"
    @edit="handleEdit"
  />
</template>

<script>
import ViewResource from '@/components/Backoffice/ResourcesManagement/ViewResource'
import ManageResourceTab from '@/mixins/ManageResourceTab'

const target = 'parkinglot'

export default {
  name: 'ParkingLotResources',
  components: {
    ViewResource
  },
  mixins: [ManageResourceTab],
  methods: {
    fetchData (form) {
      this.loading = true
      this.$api.manageResources.getByFilter({ target, form }, {
        onSuccess: (res) => {
          this.setData(res)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    deactivateService (service) {
      this.$api.manageResources.delete(
        { target, form: { ospId: service.data.ospId } },
        this.getOptions(service.filter, this.$t('resources.notifications.deactivate'))
      )
    },
    activateService (service) {
      this.$api.manageResources.activate(
        { target, form: { ospId: service.data.ospId } },
        this.getOptions(service.filter, this.$t('resources.notifications.activate'))
      )
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'BackofficeAddOrEditParking',
        params: {
          createOrEdit: 'edit'
        },
        query: {
          ospId
        }
      })
    }
  }
}
</script>
