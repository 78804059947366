<template>
  <base-section :title="$t('general.section.opening_hours')" class="opening-hours-container">
    <el-row :gutter="24">
      <el-col :span="12">
        <span class="hours-header">{{ $t('general.label.opening_hours_in') }}</span>
        <template v-for="day in days">
          <weekday-opening-hours :data="data" :type="types.in" :day="day" :key="day" />
        </template>
      </el-col>
      <el-col :span="12">
        <span class="hours-header">{{ $t('general.label.opening_hours_out') }}</span>
        <template v-for="day in days">
          <weekday-opening-hours :data="data" :type="types.out" :day="day" :key="day" />
        </template>
      </el-col>
    </el-row>
  </base-section>
</template>

<script>
import WeekdayOpeningHours from './WeekdayOpeningHours'

export default {
  name: 'OpeningHoursSection',
  components: { WeekdayOpeningHours },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    validations: {
      type: Object
    }
  },
  data () {
    return {
      types: {
        in: 'in',
        out: 'out'
      },
      days: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
    }
  }
}
</script>

<style lang="scss">
.weekday-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
  & > .el-form-item {
    margin: 0 5px;
  }
  .el-input__inner {
    outline: none;
    border-color: #b6bec9;
    color: #282c36;
    height: 32px;
    line-height: 32px;
  }
  .error {
    display: none;
  }
}
.day-label {
  margin-right: 5px;
  min-width: 85px;
}
.hours-header {
  display: block;
  margin-bottom: 1.5em;
}
.opening-hours-container {
  min-width: 1024px;
  max-width: 1200px;
}
</style>
