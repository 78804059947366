<template>
  <page-layout :title="$t('sidebar.menu_item.resouces_management')" :subTitle="getSubtitle" :has-back-button="true" :fixed-footer="true">
    <template #header-actions>
      <base-button
        prepend-icon="ri-add-line"
        :label="getButtonLabel"
        type="primary"
        @click="addResource"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span">
          <i v-if="tab.icon" :class="tab.icon"></i>
          {{ tab.label }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <router-view
      ref="routerView"
      :key="$route.name"
      :totalPages.sync="totalPages"
      :pagination.sync="pagination"
    ></router-view>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapActions } from 'vuex'
import TabNavigation from '../mixins/TabNavigation'

export default {
  name: 'BackofficeUploadsLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  data () {
    return {
      key: '',
      activeName: '',
      isMounted: false,
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15
      }
    }
  },
  watch: {
    '$route.name' () {
      setTimeout(() => {
        this.key = this.$key()
      }, 50)
    }
  },
  methods: {
    ...mapActions({
      fetchAllMrp: 'resources/fetchAllMrp',
      fetchMobilityModes: 'resources/fetchMobilityModes'
    }),
    back () {
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.push({ name: 'BackofficeViewServices' })
      }
    },
    addResource () {
      switch (this.$route.name) {
        case 'BackofficeViewServices': {
          return this.$router.push({
            name: 'BackofficeAddOrEditService',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewTransport': {
          return this.$router.push({
            name: 'BackofficeAddOrEditTransport',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewParking': {
          return this.$router.push({
            name: 'BackofficeAddOrEditParking',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewBike': {
          return this.$router.push({
            name: 'BackofficeAddOrEditBike',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewCar': {
          return this.$router.push({
            name: 'BackofficeAddOrEditCar',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewRental': {
          return this.$router.push({
            name: 'BackofficeAddOrEditRental',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewLabels': {
          return this.$router.push({
            name: 'BackofficeAddOrEditLabel',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewGroups': {
          return this.$router.push({
            name: 'BackofficeAddOrEditGroups',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        case 'BackofficeViewGasStation': {
          return this.$router.push({
            name: 'BackofficeAddOrEditGasStation',
            params: {
              createOrEdit: 'create'
            }
          })
        }
        default: {
          return undefined
        }
      }
    }
  },
  computed: {
    tabs () {
      return [
        {
          label: this.$t('resources.view.titleServiceResource'),
          name: 'BackofficeViewServices'
          // icon: 'ri-ticket-line'
        },
        {
          label: this.$t('resources.view.titleTransportStopResource'),
          name: 'BackofficeViewTransport'
          // icon: 'ri-line-chart-fill'
        },
        {
          label: this.$t('resources.view.titleParkingLotResource'),
          name: 'BackofficeViewParking'
          // icon: 'ri-riding-line'
        },
        {
          label: this.$t('resources.view.titleBikeShareResource'),
          name: 'BackofficeViewBike'
          // icon: 'ri-car-line'
        },
        {
          label: this.$t('resources.view.titleCarShareResource'),
          name: 'BackofficeViewCar'
          // icon: 'ri-car-line'
        },
        {
          label: this.$t('resources.view.titleRentalCarResource'),
          name: 'BackofficeViewRental'
          // icon: 'ri-car-line'
        },
        {
          label: this.$t('resources.view.titleResourceLabel'),
          name: 'BackofficeViewLabels'
          // icon: 'ri-car-line'
        },
        {
          label: this.$t('resources.view.titleResourceGroups'),
          name: 'BackofficeViewGroups'
          // icon: 'ri-car-line'
        },
        {
          label: this.$t('resources.view.titleResourceGas'),
          name: 'BackofficeViewGasStation'
          // icon: 'ri-car-line'
        }
      ]
    },
    getSubtitle () {
      switch (this.$route.name) {
        case 'BackofficeViewServices': {
          return this.$t('resources.view.titleServiceResource')
        }
        case 'BackofficeViewTransport': {
          return this.$t('resources.view.titleTransportStopResource')
        }
        case 'BackofficeViewParking': {
          return this.$t('resources.view.titleParkingLotResource')
        }
        case 'BackofficeViewBike': {
          return this.$t('resources.view.titleBikeShareResource')
        }
        case 'BackofficeViewCar': {
          return this.$t('resources.view.titleCarShareResource')
        }
        case 'BackofficeViewRental': {
          return this.$t('resources.view.titleRentalCarResource')
        }
        case 'BackofficeViewLabels': {
          return this.$t('resources.view.titleResourceLabel')
        }
        case 'BackofficeViewGroups': {
          return this.$t('resources.view.titleResourceGroups')
        }
        case 'BackofficeViewGasStation': {
          return this.$t('resources.view.titleResourceGas')
        }
        default: {
          return undefined
        }
      }
    },
    getButtonLabel () {
      switch (this.$route.name) {
        case 'BackofficeViewServices': {
          return this.$t('resources.buttons_label.service')
        }
        case 'BackofficeViewTransport': {
          return this.$t('resources.buttons_label.transport')
        }
        case 'BackofficeViewParking': {
          return this.$t('resources.buttons_label.parking')
        }
        case 'BackofficeViewBike': {
          return this.$t('resources.buttons_label.bike')
        }
        case 'BackofficeViewCar': {
          return this.$t('resources.buttons_label.car')
        }
        case 'BackofficeViewRental': {
          return this.$t('resources.buttons_label.rental')
        }
        case 'BackofficeViewLabels': {
          return this.$t('resources.buttons_label.labels')
        }
        case 'BackofficeViewGroups': {
          return this.$t('resources.buttons_label.groups')
        }
        case 'BackofficeViewGasStation': {
          return this.$t('resources.buttons_label.gas_station')
        }
        default: {
          return undefined
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
      this.key = this.$key()
    })
    this.fetchAllMrp()
    this.fetchMobilityModes()
  }
}
</script>

//
