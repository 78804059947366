<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')">
        <general-resource-section
          :data="form"
          :validations="$v"
          :isEditMode="isEditMode"
          :changeInfoExistLabel="handleSelectExistInfoLabel"
          :changeNameExistLabel="handleSelectExistNameLabel"
        />
        <base-checkbox :label="$t('general.label.visible')" v-model="form.visible" />
        <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>

      <location-resource-section
        :data="form"
        :changeStreetExistLabel="handleSelectExistStreetLabel"
        :changeCityExistLabel="handleSelectExistCityLabel"
        :validations="$v"
      />
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import LocationResourceSection from '@/components/Backoffice/ResourcesManagement/LocationResourceSection'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'

import ResourcesManagement from '@/mixins/ResourcesManagement'
import { required } from 'vuelidate/lib/validators'
import { validateLatitude, validateLongitude } from '@/app/helpers/validate'

const target = 'bikeshare'
export default {
  name: 'BikeShare',
  components: {
    GeneralResourceSection,
    LocationResourceSection,
    PageLayout,
    AddLabelInputs
  },
  mixins: [ResourcesManagement],
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        streetLabel: {
          defaultValue: {
            required
          }
        },
        cityLabel: {
          defaultValue: {
            required
          }
        },
        mobilityMode: {
          required
        },
        latitude: {
          required,
          validateLatitude
        },
        longitude: {
          required,
          validateLongitude
        }
      }
    }
  },
  computed: {
    getForm () {
      const form = {
        active: true,
        nameLabel: {
          ...this.form.nameLabel
        },
        streetLabel: {
          ...this.form.streetLabel
        },
        cityLabel: {
          ...this.form.cityLabel
        },
        extId: this.form.extId,
        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId,
        country: this.form.country,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        phoneNumber: this.form.phoneNumber,
        visible: this.form.visible,
        zipcode: this.form.zipcode,
        color: this.form.color
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.bike')
    }
  },
  methods: {
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleBikeShareResource')
      }
      return this.$t('resources.view.add.titleBikeShareResource')
    },
    fetchBikeData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.setFormData(res)
              this.keyForm = res.ospId
            }
          }
        )
      }
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.bike.notification.add')
          }
        )
      }
    },
    updateBikeData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.bike.notification.update')
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateBikeData()
      }
      return this.submit()
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchBikeData()
  }
}
</script>
