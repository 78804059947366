<template>
  <div>
    <base-select
      :label="$t('resources.mobility_select.mrp.label')"
      :placeholder="$t('resources.mobility_select.mrp.placeholder')"
      :disabled="isEditMode"
      :items="getMrpPartners"
      v-model="data.mrpOspId"
      :required="mrpRequired"
      :change='changeMrp'
      clearable
      filterable
      :validations=" mrpRequired ? validations.form.mrpOspId : null"
    >
      <template v-slot:option="{ items }">
        <el-option v-for="item in items" :key="item.extId" :value="item.ospId" :label="item.name">
          <div class="filters__select-option">
            <i class="ri-building-4-line"></i>
            {{ item.name }}
          </div>
        </el-option>
      </template>
    </base-select>

    <base-select
      v-if="isMobilityVisible"
      :placeholder="$t('resources.mobility_select.mode.placeholder')"
      :label="$t('resources.mobility_select.mode.label')"
      :items="getMobilityModes"
      v-model="data.mobilityMode"
      :required="modeRequired"
      clearable
      filterable
      :validations=" modeRequired ? validations.form.mobilityMode : null"
    >
      <template v-slot:option="{ items }">
        <el-option
          v-for="item in items"
          :key="item.mobilityMode"
          :value="item.mobilityMode"
          :label="getModeLabel(item)"
        >
          <div class="filters__select-option">
            <i class="ri-building-4-line"></i>
            {{ getModeLabel(item) }}
          </div>
        </el-option>
      </template>
    </base-select>
    <base-checkbox :label="$t('general.labels.checkbox.active')" v-model="data.checkbox"  v-if="checkboxAble" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ResourceProviderAndMobility',
  props: {
    changeMrp: {
      type: Function,
      default: () => {}
    },
    mrpRequired: {
      type: Boolean,
      default: false
    },
    modeRequired: {
      type: Boolean,
      default: false
    },
    checkbox: {
      default: true
    },
    data: {
      type: Object,
      default: () => ({
        mrpOspId: null,
        mobilityMode: null,
        checkbox: true
      })
    },
    checkboxAble: {
      type: Boolean,
      default: true
    },
    validations: {
      type: Object,
      default: null
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    isMobilityVisible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      getMrpPartners: 'resources/getMrpPartners',
      getMobilityModes: 'resources/getMobilityModes'
    })
  },
  methods: {
    getModeLabel (item) {
      return this.$trans(item.label) || item.mobilityMode
    }
  }
}
</script>

<style>
</style>
