<template>
  <div class="general-resource-section">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.labels.input.ext_id')"
          :disabled="isEditMode"
          :validations="validations.form.extId"
          required
          v-model="data.extId"
        />
      </el-col>
      <el-col :span="12">
        <base-select
          :label="$t('resources.mobility_select.mrp.label')"
          :placeholder="$t('resources.mobility_select.mrp.placeholder')"
          :disabled="isEditMode"
          :items="getMrpPartners"
          v-model="data.mrpOspId"
          required
          clearable
          filterable
          :validations="validations.form.mrpOspId"
        >
          <template v-slot:option="{ items }">
            <el-option
              v-for="item in items"
              :key="item.extId"
              :value="item.ospId"
              :label="item.name"
            >
              <div class="filters__select-option">
                <i class="ri-building-4-line"></i>
                {{ item.name }}
              </div>
            </el-option>
          </template>
        </base-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="general-resource-section__content">
      <el-col :span="12">
        <base-select
          :placeholder="$t('resources.mobility_select.mode.placeholder')"
          :label="$t('resources.mobility_select.mode.label')"
          :items="getMobilityModes"
          v-model="data.mobilityMode"
          required
          clearable
          filterable
          :validations="validations.form.mobilityMode"
        >
          <template v-slot:option="{ items }">
            <el-option
              v-for="item in items"
              :key="item.mobilityMode"
              :value="item.mobilityMode"
              :label="getModeLabel(item)"
            >
              <div class="filters__select-option">
                <i class="ri-building-4-line"></i>
                {{ getModeLabel(item) }}
              </div>
            </el-option>
          </template>
        </base-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GeneralResourceSection',

  props: {
    changeInfoExistLabel: {
      type: Function,
      default: () => {}
    },
    changeNameExistLabel: {
      type: Function,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => ({
        infotextLabel: {
          defaultValue: null,
          labelTranslations: []
        },
        nameLabel: {
          defaultValue: '',
          labelTranslations: []
        },
        extId: null,
        mrpOspId: null,
        mobilityMode: null
      })
    },
    validations: {
      type: Object,
      default: null
    },

    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isNameModal: false,
      isInfoModal: false
    }
  },
  computed: {
    ...mapGetters({
      getMrpPartners: 'resources/getMrpPartners',
      getMobilityModes: 'resources/getMobilityModes'
    })
  },
  methods: {
    getModeLabel (item) {
      return this.$trans(item.label) || item.mobilityMode
    },
    toggleNameLabelModal () {
      this.isNameModal = !this.isNameModal
    },
    toggleInfoLabelModal () {
      this.isInfoModal = !this.isInfoModal
    }
  }
}
</script>

<style lang='scss'>
.general-resource-section__content {
  @media (max-width: 1220px) {
    display: flex;
    flex-direction: column;
    & div {
      width: 100%;
    }
  }
}
</style>
