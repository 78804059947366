<template>
  <base-section
    :title="$t('resources.gas_station.gas_station_owners.title')"
    class="table-wrapper"
    id="fuel-stations-list"
  >
    <el-table
      ref="table"
      :data.sync="tableData"
      :row-class-name="'table-row'"
      :empty-text="$t('general.empty_table')"
      style="width: 100%"
    >
      <el-table-column
        class-name="text-bold"
        prop="name"
        min-width="150"
        :label="$t('resources.gas_station.gas_station_owners.table.name')"
      >
      </el-table-column>
      <el-table-column
        prop="activeFrom"
        min-width="75"
        :label="$t('resources.gas_station.gas_station_owners.table.valid_from')"
      >
        <template slot-scope="{ row: { activeFrom } }">
          {{ activeFrom ? $dateFormat(activeFrom) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activeTo"
        min-width="75"
        :label="$t('resources.gas_station.gas_station_owners.table.valid_to')"
      >
        <template slot-scope="{ row: { activeTo } }">
          {{ activeTo ? $dateFormat(activeTo) : '' }}
        </template>
      </el-table-column>
      <el-table-column class-name="actions-cell" width="135">
        <template slot-scope="{ row }">
          <base-button
            v-if="editable(row)"
            @click.stop="handleEdit(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
          <base-button
            v-if="deletable(row)"
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          />
        </template>
      </el-table-column>
    </el-table>
    <base-button
      @click.stop="dialogFormVisible = true"
      prepend-icon="ri-add-line"
      style="float: right"
      :label="$t('general.label.add')"
    />
    <el-dialog :visible.sync="dialogFormVisible" @close="dialogClose" :key="dialogKey">
      <el-form :model="form" id="edit-fuel-station">
        <base-select
          ref="corporateAccountOspId"
          prop="corporateAccountOspId"
          name="corporateAccountOspId"
          v-model="form.corporateAccountOspId"
          :items="selectOptions"
          :placeholder="$t('resources.gas_station.gas_station_owners.select.placeholder')"
          :label="$t('resources.gas_station.gas_station_owners.select.label')"
          :validations="$v.form.corporateAccountOspId"
          :disabled="isDisabledSelectCA"
          clearable
          filterable
        ></base-select>
        <base-datepicker
          v-model="form.activeFrom"
          :label="$t('resources.gas_station.gas_station_owners.table.valid_from')"
          :maxWidth="200"
          :picker-options="activeFromDatePickerOptions"
          :disabled="isDisabledActiveFrom"
        />
        <base-datepicker
          v-model="form.activeTo"
          :label="$t('resources.gas_station.gas_station_owners.table.valid_to')"
          :maxWidth="200"
          :picker-options="activeToDatePickerOptions"
          :disabled="isDisabledActiveTo"
        />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="dialogFormVisible = false" />
        <base-button
          :loading="updating"
          type="primary"
          :label="edit ? $t('general.button_label.save_changes') : $t('general.label.add')"
          @click="handleUpdate"
        />
      </span>
    </el-dialog>
  </base-section>
</template>

<script>
import { DateTime } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { confirmDefaultOptions } from '@/app/helpers'

const defaultFormData = () => ({
  activeFrom: null,
  activeTo: null,
  corporateAccountOspId: null,
  name: null
})

export default {
  name: 'FuelStationsList',
  props: {
    ospId: {
      type: String
    },
    mrpOspId: {
      type: String
    }
  },
  data () {
    return {
      tableData: [],
      selectOptions: [],
      dialogFormVisible: false,
      dialogKey: '',
      updating: false,
      form: defaultFormData(),
      selectedCorporateAccountOspId: null,
      edit: false
    }
  },
  validations () {
    return {
      form: {
        corporateAccountOspId: { required }
      }
    }
  },
  computed: {
    activeFromDatePickerOptions () {
      return {
        disabledDate: value => {
          if (this.form.activeTo) {
            return (
              value > DateTime.fromSQL(this.form.activeTo).startOf('day').toJSDate() ||
              value < DateTime.local().startOf('day')
            )
          }
          return value < DateTime.local().startOf('day')
        }
      }
    },
    activeToDatePickerOptions () {
      return {
        disabledDate: value => {
          if (this.form.activeFrom) {
            return (
              value < DateTime.fromSQL(this.form.activeFrom).startOf('day').toJSDate() ||
              value < DateTime.local().startOf('day')
            )
          }
          return value < DateTime.local().startOf('day')
        }
      }
    },
    isDisabledActiveFrom () {
      return DateTime.fromISO(this.form.activeFrom).startOf('day') < DateTime.local().startOf('day')
    },
    isDisabledActiveTo () {
      return DateTime.fromISO(this.form.activeTo).startOf('day') < DateTime.local().startOf('day')
    },
    isDisabledSelectCA () {
      const { activeTo, activeFrom } = this.form
      if (this.edit) {
        return this.isActiveOwner(activeTo, activeFrom)
      } else {
        return false
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        await this.$api.fuel.get(this.ospId, {
          onSuccess: res => this.setData(res)
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchCorporateAccounts () {
      const filter = {
        active: true,
        pagination: {
          page: 0,
          size: 1000,
          sort: null
        },
        partnerOspId: {
          ospId: this.mrpOspId
        }
      }
      try {
        const corporateAccounts = (await this.$api.corporateAccount.getByPartner(filter)).content
        const modifiedCorporateAccounts = corporateAccounts.map(value => ({
          label: value.name,
          value: value.ospId
        }))
        this.selectOptions = modifiedCorporateAccounts
        this.dialogKey = this.$key()
      } catch (e) {
        console.error(e)
      }
    },
    handleEdit (data) {
      this.edit = true
      this.form = { ...data }
      this.dialogFormVisible = true
      this.selectedCorporateAccountOspId = data.corporateAccountOspId
    },
    handleDelete ({ corporateAccountOspId, activeFrom, name }) {
      const requestData = {
        corporateAccountOspId,
        activeFrom,
        name,
        activeTo: DateTime.local().startOf('day').toISODate()
      }
      const preparedData = this.createPreparedData(corporateAccountOspId)
      this.$confirm(
        null,
        this.$t('resources.gas_station.gas_station_owners.confirm_delete.title'),
        confirmDefaultOptions
      )
        .then(() => {
          this.updateRequest([...preparedData, requestData])
        })
        .catch(e => console.log(e))
    },
    handleUpdate () {
      if (!this.validate()) {
        return
      }
      return this.edit
        ? this.updateRequest([...this.createPreparedData(this.selectedCorporateAccountOspId), this.form])
        : this.updateRequest([...this.tableData, this.form])
    },
    async updateRequest (data) {
      this.updating = true
      try {
        await this.$api.fuel.update({
          gasStationResourceOspId: this.ospId,
          owners: data
        })
      } catch (e) {
        console.error(e.error.response.data.message)
        this.fetchData()
      } finally {
        this.dialogClose()
      }
    },
    sortData (data) {
      return [...data].sort((a, b) => DateTime.fromISO(a.activeFrom) - DateTime.fromISO(b.activeFrom))
    },
    setData (data) {
      this.tableData = this.sortData(data)
    },
    deletable ({ activeTo, activeFrom }) {
      return this.isActiveOwner(activeTo, activeFrom)
    },
    editable ({ activeTo, activeFrom }) {
      return (
        DateTime.fromISO(activeTo).startOf('day') >= DateTime.local().startOf('day') ||
        activeTo === null ||
        activeFrom === null
      )
    },
    dialogClose () {
      this.updating = false
      this.dialogFormVisible = false
      this.form = defaultFormData()
      this.selectedCorporateAccountOspId = null
      this.edit = false
      this.dialogKey = this.$key()
      this.fetchData()
    },
    validate () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        return true
      }
    },
    createPreparedData (corporateAccountOspId) {
      return this.tableData.filter(el => el.corporateAccountOspId !== corporateAccountOspId)
    },
    isActiveOwner (activeTo, activeFrom) {
      return (
        (activeFrom === null || DateTime.fromISO(activeFrom).startOf('day') <= DateTime.local().startOf('day')) &&
        (activeTo === null || DateTime.fromISO(activeTo).startOf('day') >= DateTime.local().startOf('day'))
      )
    }
  },
  watch: {
    mrpOspId: {
      handler () {
        this.fetchCorporateAccounts()
      }
    },
    form: {
      deep: true,
      handler (v) {
        if (v.corporateAccountOspId) {
          this.form.name = this.selectOptions.filter(el => el.value === v.corporateAccountOspId)[0].label
        }
      }
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';

#fuel-stations-list {
  border-top: 1px solid $gray-200;
}

#edit-fuel-station {
  .el-form-item {
    display: flex;
    flex-direction: column;
    .el-form-item__label {
      text-align: left;
    }
  }
}
</style>
