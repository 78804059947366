<template>
  <base-section>
    <el-row>
      <el-col :span="12">
        <el-form>
          <base-select
            :label="$t('resources.mobility_select.mrp.label')"
            :placeholder="$t('resources.mobility_select.mrp.placeholder')"
            :items="getMrpPartners"
            v-model="mrpOspId"
            clearable
            filterable
            :change="resetPagination"
          >
            <template v-slot:option="{ items }">
              <el-option
                v-for="item in items"
                :key="item.extId"
                :value="item.ospId"
                :label="item.name"
              >
                <div class="filters__select-option">
                  <i class="ri-building-4-line"></i>
                  {{ item.name }}
                </div>
              </el-option>
            </template>
          </base-select>
          <base-checkbox label="Active" v-model="active" />
        </el-form>
      </el-col>
    </el-row>
    <el-table :data.sync="tableData" v-loading="loading">
      <el-table-column label="External id">
        <template slot-scope="{row}">{{row.extId}}</template>
      </el-table-column>
      <el-table-column fixed="right" :width="200" class-name="actions-cell">
        <template slot-scope="{row}">
          <base-button
            @click.stop="handleEdit(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
          <base-button
            v-if="!row.active"
            @click.stop="handleActivation(row)"
            type="success"
            size="small"
            label="activate"
          />
          <base-button
            v-else
            @click.stop="handleDeactivation(row)"
            size="small"
            type="danger"
           :label="$t('general.button_label.deactivate')"
          />
        </template>
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
export default {
  name: 'ResourceGroups',
  props: ['totalPages', 'pagination'],
  data () {
    return {
      loading: false,
      tableData: [],
      mrpOspId: null,
      active: true
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    mrpOspId: debounce(function () {
      this.fetchData()
    }, 300),
    active: debounce(function () {
      this.fetchData()
    }, 300)
  },

  computed: {
    ...mapGetters({
      getMrpPartners: 'resources/getMrpPartners'
    }),
    filter () {
      return {
        active: this.active,
        mrpOspId: this.mrpOspId || null,
        pagination: this.pagination
      }
    }
  },
  methods: {
    defaultOptions (succesNotification = null) {
      return {
        succesNotification,
        onSuccess: () => {
          this.fetchData()
        },
        ...this.finally()
      }
    },
    finally () {
      return {
        onFinally: () => {
          this.loading = false
        }
      }
    },
    resetPagination () {
      this.$emit('update:pagination', {
        page: 0,
        size: 100
      })
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.$emit('update:totalPages', totalPages)
      this.loading = false
    },
    fetchData () {
      this.loading = true
      this.$api.resourceGroups.getByFilter(this.filter, {
        onSuccess: (res) => {
          this.setData(res)
        },
        ...this.finally()
      })
    },
    handleActivation (row) {
      this.$api.resourceGroups.activate(
        { ospId: row.ospId },
        {
          ...this.defaultOptions(this.$t('resource.notifications.activate'))
        }
      )
    },
    handleDeactivation (row) {
      this.$api.resourceGroups.delete(
        { ospId: row.ospId },
        {
          ...this.defaultOptions(this.$t('resource.notifications.deactivate'))
        }
      )
    },
    handleEdit ({ ospId }) {
      this.$router.push({
        name: 'BackofficeAddOrEditGroups',
        params: {
          createOrEdit: 'edit'
        },
        query: {
          ospId
        }
      })
    }
  },
  mounted () {
    this.resetPagination()
    this.fetchData()
  }
}
</script>
