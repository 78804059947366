<template>
  <base-section :title="$t('general.section.location')">
    <el-row :gutter="24">
      <el-col :span="6">
        <base-input
          :label="$t('general.label.latitude')"
          required
          type="number"
          v-model="data.latitude"
          :validations="validations.form.latitude"
        />
      </el-col>
      <el-col :span="6">
        <base-input
          :label="$t('general.label.longitude')"
          required
          type="number"
          v-model="data.longitude"
          :validations="validations.form.longitude"
        />
      </el-col>
       <el-col :span="12">
        <base-select
          :label="$t('general.label.country')"
          v-model="data.country"
          :validations="validations.form.country"
          :items="countries"
          :default-first-option="true"
          filterable
        />
      </el-col>

    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-tel-input
          :label="$t('general.label.mobile_phone')"
          prop="phone"
          placeholder="0476 12 34 56"
          v-model="data.phoneNumber"
        />
      </el-col>
      <el-col :span='12'>
        <base-input :label="$t('general.label.zip_code')" type="number" v-model="data.zipcode" />
      </el-col>
      <el-col :span="12" >
        <div class="input-container">
          <base-input
            :key="data.streetLabel.defaultValue +'StreetKey'"
            :label="$t('general.label.street_label')"
            required
            readonly
            v-model="data.streetLabel.defaultValue"
            :validations='validations.form.streetLabel.defaultValue'
            placeholder="service"
          />
          <base-button
            :label="$t('general.label.change')"
            @click="toggleStreetLabelModal"
          />
        </div>
        <div class="input-container">
          <base-input
            :key=" data.cityLabel.defaultValue + 'CityKey'"
            :label="$t('general.label.city_label')"
            required
            readonly
            :validations="validations.form.cityLabel.defaultValue"
            v-model="data.cityLabel.defaultValue"
            placeholder="service"
          />
          <base-button :label="$t('general.label.change')"  @click="toggleCityLabelModal" />
        </div>
      </el-col>
    </el-row>
    <add-labels-modal
      v-if="streetModalShow"
      :form="data.streetLabel"
      :closeModal="toggleStreetLabelModal"
      :selectLabel="changeStreetExistLabel"
    />
    <add-labels-modal
      v-if="cityModalShow"
      :form="data.cityLabel"
      :closeModal="toggleCityLabelModal"
      :selectLabel="changeCityExistLabel"
    />
    <base-color-picker
      :value="data.color"
      :label="$t('general.label.geo')"
      @input="data.color = !$event ? null : $event.toLowerCase()"
    />
  </base-section>
</template>

<script>
import AddLabelsModal from './AddLabelsModal'
import { countries } from '@/app/services/CountriesService/CountriesService'

export default {
  name: 'LocationResourceSection',
  components: {
    AddLabelsModal
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        latitude: null,
        longitude: null,
        phoneNumber: null,
        zipcode: null,
        country: null,
        cityLabel: {
          defaultValue: '',
          labelTranslations: []
        },
        streetLabel: {
          defaultValue: '',
          labelTranslations: []
        },
        color: null
      })
    },
    validations: {
      type: Object,
      default: null
    },
    changeStreetExistLabel: {
      type: Function,
      default: () => {}
    },
    changeCityExistLabel: {
      type: Function,
      default: () => {}
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      countries: countries().getList(),
      streetModalShow: false,
      cityModalShow: false
    }
  },
  methods: {
    toggleStreetLabelModal () {
      this.streetModalShow = !this.streetModalShow
    },
    toggleCityLabelModal () {
      this.cityModalShow = !this.cityModalShow
    }
  }
}
</script>

<style>
</style>
