import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions } from 'vuex'

const defaultlabelData = { defaultValue: '', labelTranslations: [] }

export default {
  name: 'ResourcesManagement',
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      isFirstFormInit: false,
      keyForm: 'a',
      saving: false,
      ospId: null,
      isEditMode: false,
      form: {
        brand: '',
        numberOfPumps: null,
        loyaltyParticipation: true,
        parkingType: null,
        mrpPrefix: null,
        mrpZoneCode: null,
        capacity: null,
        infotextLabel: {
          defaultValue: null,
          labelTranslations: []
        },
        tokenTransmissionStrategy: null,
        tokenScope: 'SSO',
        requiresToken: false,
        nameLabel: {
          defaultValue: '',
          labelTranslations: []
        },
        budgetTypes: [],
        appFlowType: null,
        menuCategory: 'TICKET',
        url: null,
        html5Ready: false,
        extId: null,
        mrpOspId: null,
        mobilityMode: null,
        color: null,
        cityLabel: {
          defaultValue: null,
          labelTranslations: []
        },
        alternateExtLocationRef: null,
        country: null,
        extLocationRef: null,
        latitude: null,
        longitude: null,
        officialName: null,
        phoneNumber: null,
        transportType: null,
        visible: false,
        zipcode: null,
        streetLabel: {
          defaultValue: null,
          labelTranslations: []
        },
        qrCodeSupported: null,
        openingHoursDriveIn: [],
        openingHoursDriveOut: []
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.dirty = !this.isFirstFormInit
        this.isFirstFormInit = false
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllMrp: 'resources/fetchAllMrp',
      fetchMobilityModes: 'resources/fetchMobilityModes'
    }),
    handleSelectExistNameLabel (existLabel) {
      this.form.nameLabel.defaultValue = existLabel.defaultValue
      this.form.nameLabel.labelTranslations = existLabel.labelTranslations
    },
    handleSelectExistInfoLabel (existLabel) {
      this.form.infotextLabel.defaultValue = existLabel.defaultValue
      this.form.infotextLabel.labelTranslations = existLabel.labelTranslations
    },
    handleSelectExistStreetLabel (existLabel) {
      this.form.streetLabel.defaultValue = existLabel.defaultValue
      this.form.streetLabel.labelTranslations = existLabel.labelTranslations
    },
    handleSelectExistCityLabel (existLabel) {
      this.form.cityLabel.defaultValue = existLabel.defaultValue
      this.form.cityLabel.labelTranslations = existLabel.labelTranslations
    },
    handleChangeMode (mode) {
      this.form.mobilityMode = mode
    },
    handleChangeMrp (mrp) {
      this.form.mrpOspId = mrp
    },
    setExtId (id) {
      this.form.extId = id
    },
    setFormData (res) {
      this.form = {
        ...res.data,
        infotextLabel: res.data.infotextLabel !== null ? res.data.infotextLabel : { ...defaultlabelData },
        cityLabel: res.data.cityLabel !== null ? res.data.cityLabel : { ...defaultlabelData },
        streetLabel: res.data.streetLabel !== null ? res.data.streetLabel : { ...defaultlabelData }
      }

      this.isFirstFormInit = true
    },
    defaultOptions () {
      return {
        onSuccess: () => {
          this.dirty = false
          this.$router.back()
        },
        onFinally: () => {
          this.saving = false
        }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.ospId = to.query.ospId
      vm.isEditMode = to.params.createOrEdit === 'edit'
    })
  }
}
