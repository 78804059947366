<template>
  <div class="backoffice__resource-tab-view">
    <base-section>
      <el-form>
        <el-row :gutter="24">
          <el-col :span="11">
              <base-select
                :label="$t('resources.mobility_select.mrp.label')"
                :placeholder="$t('resources.mobility_select.mrp.placeholder')"
                :items="getMrpPartners"
                v-model="form.mrpOspId"
                :change="emitMrpChange"
                clearable
                filterable
              >
                <template v-slot:option="{ items }">
                  <el-option
                    v-for="item in items"
                    :key="item.extId"
                    :value="item.ospId"
                    :label="item.name"
                  >
                    <div class="filters__select-option">
                      <i class="ri-building-4-line"></i>
                      {{ item.name }}
                    </div>
                  </el-option>
                </template>
              </base-select>

              <base-select
                :placeholder="$t('resources.mobility_select.mode.placeholder')"
                :label="$t('resources.mobility_select.mode.label')"
                :items="getMobilityModes"
                v-model="form.mobilityMode"
                clearable
                filterable
              >
                <template v-slot:option="{ items }">
                  <el-option
                    v-for="item in items"
                    :key="item.mobilityMode"
                    :value="item.mobilityMode"
                    :label="getModeLabel(item)"
                  >
                    <div class="filters__select-option">
                      <i class="ri-building-4-line"></i>
                      {{ getModeLabel(item) }}
                    </div>
                  </el-option>
                </template>
              </base-select>
              <base-checkbox
                :label="$t('general.labels.checkbox.active')"
                v-model="form.checkbox"
              />
          </el-col>

          <el-col :span="11">
            <base-input :label="$t('general.labels.input.name_like')" v-model="form.nameLike" />
            <base-input :label="$t('general.labels.input.ext_id')" v-model="form.extId" />
          </el-col>
        </el-row>
      </el-form>
    </base-section>
    <el-table :data.sync="tableData" v-loading="loading" @row-click="handleEdit">
      <el-table-column :label="$t('general.labels.input.ext_id')">
        <template slot-scope="{row}">{{row.data.extId}}</template>
      </el-table-column>
      <el-table-column :label="$t('general.label.name')">
        <template slot-scope="{row}">{{$trans(row.data.nameLabel)}}</template>
      </el-table-column>
      <el-table-column :label="$t('resources.mobility_select.mode.label')">
        <template slot-scope="{row}">{{row.data.mobilityMode}}</template>
      </el-table-column>
      <el-table-column fixed="right" :width="200" class-name="actions-cell">
        <template slot-scope="{row}">
          <base-button
            @click.stop="handleEdit(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          />
          <base-button
            v-if="!row.data.active"
            @click.stop="handleActivation(row)"
            size="small"
            type="success"
            :label="$t('general.button_label.activate')"
          />
          <base-button
            v-else
            @click.stop="handleDeactivation(row)"
            size="small"
            type="danger"
            :label="$t('general.button_label.deactivate')"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewResource',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        mrpOspId: null,
        mobilityMode: null,
        extId: null,
        nameLike: null,
        checkbox: true
      }
    }
  },
  computed: {
    ...mapGetters({
      getMrpPartners: 'resources/getMrpPartners',
      getMobilityModes: 'resources/getMobilityModes'
    }),
    filter () {
      return {
        mrpOspId: this.form.mrpOspId || null,
        mobilityMode: this.form.mobilityMode || null,
        extId: this.form.extId || null,
        nameLike: this.form.nameLike || null,
        active: this.form.checkbox,
        pagination: this.pagination
      }
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.emitFetchResource()
    }, 200),
    form: {
      deep: true,
      handler: debounce(function () {
        this.emitFetchResource()
      }, 700)
    }
  },
  methods: {
    emitFetchResource () {
      this.$emit('fetchResources', this.filter)
    },
    handleEdit (row) {
      this.$emit('edit', row)
    },
    handleDeactivation (row) {
      this.$emit('deactivate', { data: row, filter: this.filter })
    },
    handleActivation (row) {
      this.$emit('activate', { data: row, filter: this.filter })
    },
    emitMrpChange () {
      this.$emit('changeMrp')
    },
    modeChange (mode) {
      this.mobilityMode = mode
    },
    changeCheckbox (value) {
      this.checkbox = value
    },
    getModeLabel (item) {
      return this.$trans(item.label) || item.mobilityMode
    }
  },
  mounted () {
    this.emitFetchResource()
  }
}
</script>
