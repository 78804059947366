<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="loading" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <base-section :title="$t('resources.section.general')">
    <el-form :key="keyForm">
      <el-row>
        <el-col :span="10">
          <base-input
            :label="$t('general.labels.input.ext_id')"
            :disabled="isEditMode"
            required
            :validations="$v.form.extId"
            v-model="form.extId"
          />
          <resource-provider-and-mobility
            mrpRequired
            :validations="$v"
            :isEditMode="isEditMode"
            :data="form"
            :mrpOspId="form.mrpOspId"
            :isMobilityMode="false"
            :isMobilityVisible="false"
            :checkboxAble="false"
          />
        </el-col>
      </el-row>

      <base-section v-if="isEditMode">
        <base-transfer
          v-loading="loading"
          :top-titles="[$t('resources.view.groups.trans.left'),$t('resources.view.groups.trans.right')]"
          filterable
          :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
          v-model="resources"
          :height="470"
          :full-width="true"
          :data="groupsData"
        />
      </base-section>
    </el-form>
     </base-section>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import ResourceProviderAndMobility from '@/components/Backoffice/ResourcesManagement/ResourceProviderAndMobility'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'Groups',
  components: {
    ResourceProviderAndMobility,
    PageLayout
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      loading: false,
      mounted: false,
      groupsData: [],
      isEditMode: false,
      ospId: null,
      keyForm: 'a',
      resources: [],
      form: {
        mrpOspId: null,
        extId: null
      }
    }
  },
  validations () {
    return {
      form: {
        extId: {
          required
        },
        mrpOspId: {
          required
        }
      }
    }
  },
  watch: {
    resources: function (newResources, oldResources) {
      this.addOrRemoveResources(newResources, oldResources)
    },
    form: {
      deep: true,
      handler: function () {
        this.dirty = true
      }
    }
  },
  computed: {
    filter () {
      return {
        active: true,
        mrpOspId: this.form.mrpOspId,
        pagination: { size: 1000, page: 0 }
      }
    },
    getCreateForm () {
      return { ...this.form }
    },
    getFormWithResourceGroup () {
      return {
        ...this.filter,
        resourcegroups: [{ ospId: this.ospId }]
      }
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.groups')
    }
  },
  methods: {
    ...mapActions({
      fetchAllMrp: 'resources/fetchAllMrp'
    }),
    getGroupData () {
      if (this.isEditMode) {
        return this.$api.resourceGroups.get(
          { ospId: this.ospId },
          {
            onSuccess: (res) => {
              this.form = res
              this.keyForm = this.$key()
            }
          }
        )
      }
    },
    fetchGroups () {
      if (this.isEditMode) {
        this.loading = true
        return this.$api.resource.getByFilter(this.filter, {
          onSuccess: (res) => {
            this.groupsData = res.content.map((g) => {
              return {
                key: g.ospId,
                label: g.data.extId
              }
            })
          },
          onFinally: () => {
            this.loading = false
          }
        })
      }
    },
    fetchSelectedResources () {
      if (this.isEditMode) {
        return this.$api.resource.getByFilter(this.getFormWithResourceGroup, {
          onSuccess: (res) => {
            this.resources = res.content.map((r) => r.ospId)
            this.keyForm = this.$key()
          }
        })
      }
    },
    addResources (resources) {
      this.$api.resourceGroups.add(
        {
          resourcegroup: { ospId: this.ospId },
          resources: resources
        },
        {
          successNotification: this.$t('resources.groups.notification.add')
        }
      )
    },
    removeResources (resources) {
      this.$api.resourceGroups.remove(
        {
          resourcegroup: { ospId: this.ospId },
          resources: resources
        },
        {
          successNotification: this.$t('resources.groups.notification.remove')
        }
      )
    },
    addOrRemoveResources (newResources, oldResources) {
      if (this.mounted) {
        if (newResources.length < oldResources.length) {
          const removedResources = oldResources.filter((r, i) => r !== newResources[i])
          return this.removeResources(removedResources)
        }

        const addedResources = newResources.filter((r, i) => r !== oldResources[i])
        this.addResources(addedResources)
      }
    },
    getSubmit () {
      this.$v.$touch()
      if (!this.isEditMode && !this.$v.$invalid) {
        return this.$api.resourceGroups.create(this.getCreateForm, {
          onSuccess: (res) => {
            this.dirty = false
            this.$router.back()
          },
          successNotification: this.$t('resources.groups.notification.create')
        })
      }
      if (this.isEditMode) {
        return this.$router.back()
      }
    },
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleResourceGroups')
      }
      return this.$t('resources.view.add.titleResourceGroups')
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.ospId = to.query.ospId
      vm.isEditMode = to.params.createOrEdit === 'edit'
    })
  },
  async mounted () {
    this.fetchAllMrp()
    await this.getGroupData()
    await this.fetchSelectedResources()
    await this.fetchGroups()
    this.mounted = true
    this.keyForm = this.$key()
  }
}
</script>

<style>
</style>
