<template>
  <div>
    <el-form @submit.prevent>
      <base-input :label="$t('general.label.text_like')" v-model="translationLike" />
    </el-form>
    <el-table :data="tableData" :height="450" v-loading="loading">
      <el-table-column :min-width="250" :label="$t('general.input.defaultValue_label')">
        <template slot-scope="{row}">{{$trans(row)}}</template>
      </el-table-column>
      <el-table-column fixed="right" :width="120">
        <template slot-scope="{row}">
          <base-button
            :label="$t('general.button_labels.select')"
            @click="handleSelect(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <template v-if="totalPages && totalPages > 1">
      <base-pagination
        class="--height-auto"
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash'
const target = 'label'
export default {
  name: 'AddExistLabelTab',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      tableData: [],
      totalPages: 0,
      translationLike: null,
      pagination: {
        page: 0,
        size: 20
      }
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    translationLike: debounce(function () {
      this.fetchData()
    }, 200)
  },
  computed: {
    filter () {
      return {
        translationLike: this.translationLike || null,
        pagination: this.pagination
      }
    }
  },
  methods: {
    setData ({ content, totalPages }) {
      this.tableData = content
      this.totalPages = totalPages
      this.loading = false
    },
    fetchData () {
      this.loading = true
      this.$api.manageResources.getByFilter(
        { target, form: this.filter },
        {
          onSuccess: (res) => {
            this.setData(res)
          },
          onFinally: () => {
            this.loading = false
          }
        }
      )
    },
    handleSelect (row) {
      this.$emit('selectLabel', row)
      this.closeModal()
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang='scss'>
.--height-auto {
  height: auto;
}
</style>
