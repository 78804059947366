
<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')">
        <general-resource-section
          :data="form"
          :validations="$v"
          :isEditMode="isEditMode"
          :changeInfoExistLabel="handleSelectExistInfoLabel"
          :changeNameExistLabel="handleSelectExistNameLabel"
        />
        <base-checkbox :label="$t('general.label.visible')" v-model="form.visible" />
        <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>

      <base-section :title="$t('general.label.details')">
        <el-row>
          <el-col :span="12">
            <base-input
              :validations="$v.form.brand"
              required
              :label="$t('general.label.brand')"
              v-model="form.brand"
            />
            <base-input
              :label="$t('general.label.number_of_pumps')"
              type="number"
              v-model="form.numberOfPumps"
              :validations="$v.form.numberOfPumps"
            />
            <base-checkbox
              :label="$t('general.label.loyalty_participation')"
              v-model="form.loyaltyParticipation"
            />
          </el-col>
        </el-row>
      </base-section>
      <location-resource-section
        :data="form"
        :changeStreetExistLabel="handleSelectExistStreetLabel"
        :changeCityExistLabel="handleSelectExistCityLabel"
        :validations="$v"
      />
    </el-form>
    <fuel-stations-list v-if="isEditMode" :ospId="ospId" :mrpOspId="form.mrpOspId"/>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import LocationResourceSection from '@/components/Backoffice/ResourcesManagement/LocationResourceSection'
import ResourcesManagement from '@/mixins/ResourcesManagement'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'
import FuelStationsList from '@/components/Backoffice/ResourcesManagement/FuelStationsList.vue'

import { required } from 'vuelidate/lib/validators'
import { validateLatitude, validateLongitude } from '@/app/helpers/validate'
const target = 'gasStation'
const defaultlabelData = { defaultValue: '', labelTranslations: [] }

const isValidNumberOfPumps = (value) => {
  if (value) {
    return !String(value).match(/[.,]/g)
  }
  return true
}

export default {
  name: 'GasStation',
  components: {
    GeneralResourceSection,
    LocationResourceSection,
    PageLayout,
    AddLabelInputs,
    FuelStationsList
  },
  mixins: [ResourcesManagement],
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        streetLabel: {
          defaultValue: {
            required
          }
        },
        cityLabel: {
          defaultValue: {
            required
          }
        },
        mobilityMode: {
          required
        },
        latitude: {
          required,
          validateLatitude
        },
        longitude: {
          required,
          validateLongitude
        },
        brand: {
          required
        },
        numberOfPumps: {
          isValidNumberOfPumps
        }
      }
    }
  },
  computed: {
    getForm () {
      const form = {
        active: true,
        nameLabel: {
          ...this.form.nameLabel
        },
        streetLabel: {
          ...this.form.streetLabel
        },
        cityLabel: {
          ...this.form.cityLabel
        },
        extId: this.form.extId,
        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId,
        country: this.form.country,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        phoneNumber: this.form.phoneNumber,
        visible: this.form.visible,
        zipcode: this.form.zipcode,
        loyaltyParticipation: this.form.loyaltyParticipation,
        brand: this.form.brand,
        numberOfPumps: this.form.numberOfPumps,
        color: this.form.color
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.gas_station')
    }
  },
  methods: {
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleGasStationResource')
      }
      return this.$t('resources.view.add.titleGasStationResource')
    },
    fetchParkingData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.form = {
                ...res.data,
                infotextLabel:
                  res.data.infotextLabel !== null
                    ? res.data.infotextLabel
                    : { ...defaultlabelData },
                streetLabel:
                  res.data.streetLabel !== null
                    ? res.data.streetLabel
                    : { ...defaultlabelData },
                cityLabel:
                  res.data.cityLabel !== null
                    ? res.data.cityLabel
                    : { ...defaultlabelData }
              }
              this.keyForm = res.ospId
            }
          }
        )
      }
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.gas_station.notification.add'
            )
          }
        )
      }
    },
    updateGasStationData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.gas_station.notification.update'
            )
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateGasStationData()
      }
      return this.submit()
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchParkingData()
  }
}
</script>
