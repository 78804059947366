
<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')">
        <general-resource-section
          :data="form"
          :validations="$v"
          :isEditMode="isEditMode"
          :changeInfoExistLabel="handleSelectExistInfoLabel"
          :changeNameExistLabel="handleSelectExistNameLabel"
        />
        <base-checkbox label="visible" v-model="form.visible" />
        <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>
      <base-section :title="$t('general.label.details')">
        <el-row>
          <el-col :span="12">
             <base-select
              :label="$t('general.label.transport_type')"
              required
              :items="transportTypes"
              v-model="form.transportType"
              :validations="$v.form.transportType"
            />
            <base-input
              :label="$t('general.label.ext_location')"
              required
              v-model="form.extLocationRef"
              :validations="$v.form.extLocationRef"
            />

            <base-input
              :label="$t('general.label.alt_ext_location')"
              v-model="form.alternateExtLocationRef"
            />
            <base-input :label="$t('general.label.official_name')" v-model="form.officialName" />

          </el-col>
        </el-row>
      </base-section>
       <location-resource-section
          :data="form"
          :changeStreetExistLabel="handleSelectExistStreetLabel"
          :changeCityExistLabel="handleSelectExistCityLabel"
          :validations="$v"
        />
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import LocationResourceSection from '@/components/Backoffice/ResourcesManagement/LocationResourceSection'
import ResourcesManagement from '@/mixins/ResourcesManagement'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'

import { required } from 'vuelidate/lib/validators'
import { validateLatitude, validateLongitude } from '@/app/helpers/validate'

const target = 'transportStop'

export default {
  name: 'TransportStop',
  components: {
    GeneralResourceSection,
    LocationResourceSection,
    PageLayout,
    AddLabelInputs
  },
  mixins: [ResourcesManagement],
  data () {
    return {
      transportTypes: [
        {
          value: 'BUS'
        },
        {
          value: 'TRAIN'
        }
      ]
    }
  },
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        mobilityMode: {
          required
        },
        latitude: {
          required,
          validateLatitude
        },
        longitude: {
          required,
          validateLongitude
        },
        transportType: {
          required
        },
        extLocationRef: {
          required
        },
        streetLabel: {
          defaultValue: {
            required
          }
        },
        cityLabel: {
          defaultValue: {
            required
          }
        }
      }
    }
  },
  computed: {
    getForm () {
      const form = {
        active: true,
        nameLabel: {
          ...this.form.nameLabel
        },
        infotextLabel: {
          ...this.form.infotextLabel
        },
        streetLabel: {
          ...this.form.streetLabel
        },
        cityLabel: {
          ...this.form.cityLabel
        },
        extId: this.form.extId,
        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId,
        alternateExtLocationRef: this.form.alternateExtLocationRef,
        country: this.form.country,
        extLocationRef: this.form.extLocationRef,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        officialName: this.form.officialName,
        phoneNumber: this.form.phoneNumber,
        transportType: this.form.transportType,
        visible: this.form.visible,
        zipcode: this.form.zipcode,
        color: this.form.color
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.transport')
    }
  },
  methods: {
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleTransportStopResource')
      }
      return this.$t('resources.view.add.titleTransportStopResource')
    },
    fetchTransportData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.setFormData(res)
              this.keyForm = res.ospId
            }
          }
        )
      }
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.view.transport.nontification.add'
            )
          }
        )
      }
    },
    updateTransportData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.view.transport.nontification.update'
            )
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateTransportData()
      }
      return this.submit()
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchTransportData()
  }
}
</script>
