export default {
  name: 'ManageResourceTab',
  props: ['totalPages', 'pagination'],
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  methods: {
    getOptions (filter, successNotification) {
      return {
        successNotification,
        onSuccess: () => {
          this.fetchData(filter)
        },
        onFinally: () => {
          this.loading = false
        }

      }
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.$emit('update:totalPages', totalPages)
      this.loading = false
    },
    resetPagination () {
      this.$emit('update:pagination', {
        page: 0,
        size: 15
      })
      this.$emit('update:totalPages', 0)
    }
  },
  mounted () {
    this.resetPagination()
  }
}
