<template>
  <div class="weekday-container" :key="key">
    <span class="day-label"> {{ $t(`general.weekday.${day.toLowerCase()}`) }}</span>
    <base-input v-model="firstOpen" :maxWidth="70" v-mask="timeMask" :error="firstOpenError" />-
    <base-input v-model="firstClose" :maxWidth="70" v-mask="timeMask" :error="firstCloseError" />
    <base-input v-model="secondOpen" :maxWidth="70" v-mask="timeMask" :error="secondOpenError" />-
    <base-input v-model="secondClose" :maxWidth="70" v-mask="timeMask" :error="secondCloseError" />
  </div>
</template>

<script>
import { validateTime } from '@/app/helpers/validate'

const sortData = (a, b) => {
  if (a.open.length && a.open.length < 5) {
    return 0
  }
  const firstCompared = Number(a.open.replace(/:/g, ''))
  const secondCompared = Number(b.open.replace(/:/g, ''))
  if (firstCompared > secondCompared) {
    return 1
  }
  if (firstCompared < secondCompared) {
    return -1
  }
  return 0
}

export default {
  name: 'WeekdayOpeningHours',
  props: {
    day: {
      type: String
    },
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      firstOpen: null,
      firstClose: null,
      secondOpen: null,
      secondClose: null,
      firstOpenError: '',
      firstCloseError: '',
      secondOpenError: '',
      secondCloseError: '',
      key: '',
      timeMask: {
        mask: 'AB:CB',
        tokens: {
          A: { pattern: /[0-2]/ },
          B: { pattern: /[0-9]/ },
          C: { pattern: /[0-5]/ }
        }
      }
    }
  },
  computed: {
    dataByType () {
      if (this.type === 'in') {
        return this.data.openingHoursDriveIn
      } else {
        return this.data.openingHoursDriveOut
      }
    },
    sortedData () {
      return this.dataByType.filter(el => el.dayOfWeek === this.day).sort(sortData)
    }
  },
  methods: {
    getOpeningHoursInDay () {
      if (!this.dataByType) {
        return
      }
      if (this.sortedData.length && this.sortedData.length >= 1) {
        this.firstOpen = this.sortedData[0].open
        this.firstClose = this.sortedData[0].close
      }
      if (this.sortedData.length && this.sortedData.length === 2) {
        this.secondOpen = this.sortedData[1].open
        this.secondClose = this.sortedData[1].close
      }
      this.key = this.$key()
    },
    changeTypeOfData () {
      if (!this.dataByType) {
        if (this.type === 'in') {
          this.data.openingHoursDriveIn = []
        } else {
          this.data.openingHoursDriveOut = []
        }
      }
    },
    checkEmptyData () {
      const checkPresenceOfDay = this.dataByType.some(el => el.dayOfWeek === this.day)
      if (!this.dataByType.length || !checkPresenceOfDay) {
        this.dataByType.push({ close: '', dayOfWeek: this.day, open: '' })
      }
    }
  },
  mounted () {
    this.getOpeningHoursInDay()
  },
  watch: {
    firstOpen (v) {
      this.changeTypeOfData()
      this.checkEmptyData()
      if (!validateTime(v)) {
        this.firstOpenError = 'invalid'
      } else {
        this.firstOpenError = ''
      }
      this.sortedData[0].open = v
    },
    firstClose (v) {
      if (!validateTime(v)) {
        this.firstCloseError = 'invalid'
      } else {
        this.firstCloseError = ''
      }
      if (!this.sortedData[0]) {
        return
      }
      this.sortedData[0].close = v
    },
    secondOpen (v) {
      if (!validateTime(v)) {
        this.secondOpenError = 'invalid'
      } else {
        this.secondOpenError = ''
      }
      if (this.sortedData.length <= 1) {
        this.dataByType.push({ close: '', dayOfWeek: this.day, open: '23:59' })
      }
      this.sortedData[1].open = v
    },
    secondClose (v) {
      if (!validateTime(v)) {
        this.secondCloseError = 'invalid'
      } else {
        this.secondCloseError = ''
      }
      if (!this.sortedData[1]) {
        return
      }
      this.sortedData[1].close = v
    }
  }
}
</script>

<style></style>
