<template>
  <el-form>
    <base-input
      required
      v-model="data.defaultValue"
      :validations='validations'
      :label="$t('general.input.defaultValue_label')"
    />
    <base-translations :label="$t('general.label.translations')" v-model="data.labelTranslations" />
  </el-form>
</template>

<script>
export default {
  name: 'AddNewLabelTab',
  props: {
    validations: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: () => ({
        defaultValue: null,
        labelTranslations: []
      })
    }
  }
  //   defaultValue: function (value) {
  //     this.$emit('addDefaultValue', value)
  //   }
  // }
}
</script>
