<template>
  <el-dialog width="70%" :visible="true" @close="closeModal" :title="title">
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span">{{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <div class="content-container">
      <add-new-label-tab
        v-if="getCurrentTab === 'NewLabel'"
        :data="form"
        :validations="$v.form.defaultValue"
      />
      <add-exist-label-tab v-else @selectLabel="selectLabel" :closeModal="closeModal"/>
    </div>
    <template slot="footer" class="dialog-footer">
      <div class="button-container">
        <base-button type="primary" :label="$t('general.confirm')" @click="handleSaveData"/>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import AddNewLabelTab from './AddNewLabelTab'
import AddExistLabelTab from './AddExistLabelTab'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddLabelsModal',
  components: {
    AddNewLabelTab,
    AddExistLabelTab
  },
  props: {
    title: {
      default: 'New label'
    },
    closeModal: {
      type: Function,
      default: () => {
      }
    },
    selectLabel: {
      type: Function,
      default: () => {
      }
    },
    form: {
      type: Object,
      default: () => ({
        defaultValue: null,
        labelTranslations: []
      })
    }
  },
  data () {
    return {
      activeName: 'NewLabel'
    }
  },
  computed: {
    tabs () {
      return [
        {
          label: this.$t('resource.labels_modal_tab.new'),
          name: 'NewLabel'
        },
        {
          label: this.$t('resource.labels_modal_tab.exist'),
          name: 'existLabel'
        }
        // {
        //   label: this.$t('resource.labels_modal_tab.no_label'),
        //   name: 'noLabel'
        // }
      ]
    },
    getCurrentTab () {
      return this.activeName
    }
  },
  validations: {
    form: {
      defaultValue: {
        required
      }
    }
  },
  methods: {
    handleSaveData () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.activeName = 'NewLabel'
        return false
      }
      this.closeModal()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$watch('form', (v) => {
        // console.log('form changed')
        if (v.labelOspId) {
          v.labelOspId = undefined
        }
      }, { deep: true })
    })
  }
}
</script>

<style lang='scss'>
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.content-container {
  width: 100%;
  position: relative;
}
</style>
