<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')">
        <general-resource-section :data="form" :validations="$v" :isEditMode="isEditMode" />
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              required
              clearable
              :label="$t('resources.select.menu_category')"
              v-model="form.menuCategory"
              :items="menuCategories"
              :validations="$v.form.menuCategory"
            />
            <base-select
              :label="$t('resources.input.app_flow_type')"
              v-model="form.appFlowType"
              :items="flowTypes"
              filterable
              clearable
            />
          </el-col>
        </el-row>
         <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>
      <base-section :title="$t('general.label.details')">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-checkbox
              :label="$t('resources.checkbox.html5')"
              :disabled="isHtml5Disabled"
              v-model="form.html5Ready"
            />
            <base-input
              required
              :label="$t('general.label.url')"
              v-model="form.url"
              :validations="$v.form.url"
              v-if="isUrlInputVisible"
            />
          </el-col>
        </el-row>

        <el-row :gutter="24" v-if="isExtWebTypeChecked">
          <el-col :span="12">
            <base-checkbox
              v-model="form.requiresToken"
              :label="$t('general.label.requires_token')"
              required
            />
            <base-select
              :disabled="!form.requiresToken"
              :required="form.requiresToken"
              :label="$t('general.label.trans_strat')"
              v-model="form.tokenTransmissionStrategy"
              :items="tokensStrategy"
              :validations="$v.form.tokenTransmissionStrategy"
            />
            <base-select
              :disabled="!form.requiresToken"
              :required="form.requiresToken"
              :label="$t('general.label.trans_scope')"
              :items="tokensScope"
              v-model="form.tokenScope"
              :validations="$v.form.tokenScope"
            />
          </el-col>
        </el-row>
      </base-section>
      <base-section :title="$t('general.section.budget')">
        <base-transfer
          :top-titles="[$t('resources.view.service.trans.left'),$t('resources.view.service.trans.right')]"
          filterable
          :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
          v-model="form.budgetTypes"
          :height="470"
          :full-width="true"
          :data="budgetTypes"
        ></base-transfer>
      </base-section>
    </el-form>

    <base-section v-if="isEditMode" :title="$t('resources.view.service.linked_section')">
      <el-table :data="linkedResources">
        <el-table-column prop="extId" :label="$t('general.label.extid')" />
        <el-table-column :label="$t('general.label.name')">
          <template slot-scope="{row}">{{$trans(row.productLabel)}}</template>
        </el-table-column>
      </el-table>
    </base-section>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'
import ResourcesManagement from '@/mixins/ResourcesManagement'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'
import { mapGetters } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'

const withUrlOrNot = (checkHTML5) =>
  helpers.withParams({ type: 'required' }, (value) => {
    return checkHTML5 ? helpers.req(value) : true
  })
const isRequiresToken = (requiresToken) =>
  helpers.withParams({ type: 'required' }, (value) => {
    return requiresToken ? helpers.req(value) : true
  })
const budgetTypeRepository = new BudgetTypeRepository()
const target = 'serviceResource'

const EXT_WEB = 'EXT_WEB'

export default {
  name: 'Service',
  components: {
    GeneralResourceSection,
    PageLayout,
    AddLabelInputs
  },
  mixins: [ResourcesManagement],
  data () {
    return {
      flowTypes: [
        { value: 'VOUCHER' },
        { value: 'WEB' },
        { value: 'EXT_WEB' },
        { value: 'REGISTRATION' },
        { value: 'RENTAL' },
        { value: 'SEASON_TICKET' },
        { value: 'MULTI_PRODUCT' },
        { value: 'ONSTREET_PARKING' },
        { value: 'ROUTE_RESERVATION' },
        { value: 'MULTI_PRODUCT_SCAN' },
        { value: 'TIMETABLE' },
        { value: 'FUEL' },
        { value: 'CARPOOL' },
        { value: 'CARSHARE' },
        { value: 'SEASON_TICKET_V2' },
        { value: 'PLUGIN_SERVICE' },
        { value: 'DAKT' },
        { value: 'OFFSTREET_PARKING' }
      ],
      menuCategories: [{ value: 'TICKET' }, { value: 'SEASON_TICKET' }],
      budgetTypes: [],
      linkedResources: [],
      tokensStrategy: [{ value: 'HEADER' }, { value: 'REQUEST_PARAM' }],
      tokensScope: [{ value: 'SSO' }]
    }
  },
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        url: {
          withUrlOrNot: withUrlOrNot(this.form.html5Ready || this.isExtWebTypeChecked)
        },
        tokenTransmissionStrategy: {
          strategy: isRequiresToken(this.form.requiresToken)
        },
        tokenScope: {
          scope: isRequiresToken(this.form.requiresToken)
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        menuCategory: {
          required
        },
        mobilityMode: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    getBudgetTypes () {
      return this.budgetTypes
    },
    getForm () {
      const form = {
        active: true,
        requiresToken: this.form.requiresToken,
        tokenTransmissionStrategy: this.form.tokenTransmissionStrategy,
        tokenScope: this.form.requiresToken ? this.form.tokenScope : null,
        nameLabel: {
          ...this.form.nameLabel
        },
        appFlowType: this.form.appFlowType,
        extId: this.form.extId,
        html5Ready: this.form.html5Ready,
        menuCategory: this.form.menuCategory,
        url: this.form.url,
        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId,
        budgetTypes: this.form.budgetTypes
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.service')
    },
    isExtWebTypeChecked () {
      return this.form.appFlowType === EXT_WEB
    },
    isUrlInputVisible () {
      return this.form.html5Ready || this.isExtWebTypeChecked
    },
    isHtml5Disabled () {
      return this.form.appFlowType === EXT_WEB
    }
  },
  methods: {
    fetchBudgetTypes () {
      return budgetTypeRepository.getAll(this.corporateAccount.extId, {
        onSuccess: (r) => {
          this.budgetTypes = r.map((b) => {
            return {
              key: b.budgetType,
              label: this.$trans(b.budgetTypeName)
            }
          })
        }
      })
    },
    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.saving = true
        this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.view.service.notification.add'
            )
          }
        )
      }
    },
    fetchServiceData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.setFormData(res)
              this.keyForm = res.ospId
            }
          }
        )
        this.$api.product.findByResource(
          { resourceOspId: this.ospId },
          {
            onSuccess: (res) => {
              this.linkedResources = res
            }
          }
        )
      }
    },
    updateServiceData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.view.service.notification.update'
            )
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateServiceData()
      }
      return this.submit()
    },
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleServiceResource')
      }
      return this.$t('resources.view.add.titleServiceResource')
    }
  },
  mounted () {
    this.fetchBudgetTypes()
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchServiceData()
  }
}
</script>
