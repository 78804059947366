<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')" id="generalInfoSection">
        <general-resource-section
          :data="form"
          :validations="$v"
          :isEditMode="isEditMode"
          :changeInfoExistLabel="handleSelectExistInfoLabel"
          :changeNameExistLabel="handleSelectExistNameLabel"
        />
        <base-checkbox :label="$t('general.label.visible')" v-model="form.visible" />
        <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>

      <base-section :title="$t('general.label.details')" id="detailsSection">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('general.label.parking_type')"
              required
              :items="parpkingTypes"
              v-model="form.parkingType"
              :validations="$v.form.parkingType"
            />
            <base-input :label="$t('general.label.capacity')" v-model="form.capacity" />
            <base-input :label="$t('general.label.mrp_pref')" v-model="form.mrpPrefix" />
            <base-input :label="$t('general.label.mrp_zone')" v-model="form.mrpZoneCode" />
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('general.label.qr_code_supported')" class="flexible-form-item">
              <el-select v-model="form.qrCodeSupported" placeholder="" class="small-form-item" clearable>
                <el-option v-for="item in qrCodeOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </base-section>
      <location-resource-section
        :data="form"
        :changeStreetExistLabel="handleSelectExistStreetLabel"
        :changeCityExistLabel="handleSelectExistCityLabel"
        :validations="$v"
        id="locationSection"
      />
      <opening-hours-section :data="form" id="openingHoursSection" :validations="$v" />
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import LocationResourceSection from '@/components/Backoffice/ResourcesManagement/LocationResourceSection'
import OpeningHoursSection from '@/components/Backoffice/ResourcesManagement/OpeningHoursSection'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'

import ResourcesManagement from '@/mixins/ResourcesManagement'

import { required } from 'vuelidate/lib/validators'
import { validateLatitude, validateLongitude } from '@/app/helpers/validate'

const target = 'parkinglot'

export default {
  name: 'ParkingLot',
  components: {
    GeneralResourceSection,
    LocationResourceSection,
    PageLayout,
    AddLabelInputs,
    OpeningHoursSection
  },
  mixins: [ResourcesManagement],
  data () {
    return {
      parpkingTypes: [
        {
          value: 'PRIVATE'
        },
        {
          value: 'ON_STREET'
        }
      ],
      qrCodeOptions: [
        {
          value: true,
          label: this.$t('general.yes')
        },
        {
          value: false,
          label: this.$t('general.no')
        }
      ]
    }
  },
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        streetLabel: {
          defaultValue: {
            required
          }
        },
        cityLabel: {
          defaultValue: {
            required
          }
        },
        mobilityMode: {
          required
        },
        latitude: {
          required,
          validateLatitude
        },
        longitude: {
          required,
          validateLongitude
        },
        parkingType: {
          required
        }
      }
    }
  },
  computed: {
    getForm () {
      const form = {
        active: true,
        nameLabel: {
          ...this.form.nameLabel
        },
        streetLabel: {
          ...this.form.streetLabel
        },
        cityLabel: {
          ...this.form.cityLabel
        },
        extId: this.form.extId,
        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId,
        country: this.form.country,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        phoneNumber: this.form.phoneNumber,
        parkingType: this.form.parkingType,
        visible: this.form.visible,
        zipcode: this.form.zipcode,
        mrpPrefix: this.form.mrpPrefix,
        mrpZoneCode: this.form.mrpZoneCode,
        capacity: this.form.capacity,
        color: this.form.color,
        qrCodeSupported: this.form.qrCodeSupported,
        openingHoursDriveIn: this.form.openingHoursDriveIn
          ? this.form.openingHoursDriveIn.filter(el => el.open !== '').filter(el => el.close !== '')
          : null,
        openingHoursDriveOut: this.form.openingHoursDriveOut
          ? this.form.openingHoursDriveOut.filter(el => el.open !== '').filter(el => el.close !== '')
          : null
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.parking')
    }
  },
  methods: {
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleParkingLotResource')
      }
      return this.$t('resources.view.add.titleParkingLotResource')
    },
    fetchParkingData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: res => {
              this.setFormData(res)
              this.keyForm = res.ospId
            }
          }
        )
      }
    },
    submit () {
      if (this.validate()) {
        this.saving = true
        return this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.parking.notification.add')
          }
        )
      }
    },
    updateParkingData () {
      if (this.validate()) {
        this.saving = true
        return this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.parking.notification.update')
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateParkingData()
      }
      return this.submit()
    },
    validate () {
      const {
        streetLabel,
        cityLabel,
        latitude,
        longitude,
        parkingType,
        extId,
        mobilityMode,
        nameLabel,
        mrpOspId
      } = this.$v.form
      this.$v.form.$touch()
      if (extId.$invalid || mobilityMode.$invalid || nameLabel.$invalid || mrpOspId.$invalid) {
        this.$scrollTo('#generalInfoSection')
        return false
      }
      if (parkingType.$invalid) {
        this.$scrollTo('#detailsSection')
        return false
      }
      if (streetLabel.$invalid || cityLabel.$invalid || latitude.$invalid || longitude.$invalid) {
        this.$scrollTo('#locationSection')
        return false
      }
      return true
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchParkingData()
  }
}
</script>

<style lang="scss">
.flexible-form-item {
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.small-form-item {
  width: 100px !important;
}
</style>
