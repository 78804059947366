<template>
  <div>
    <base-section>
      <el-row>
        <el-col :span="12">
          <el-form @submit.prevent>
            <base-input :label="$t('general.label.text_like')" v-model="translationLike" />
          </el-form>
        </el-col>
      </el-row>
    </base-section>
    <base-section>
      <el-table :data.sync="tableData" v-loading="loading">
        <el-table-column :label="$t('general.input.defaultValue_label')">
          <template slot-scope="{row}">{{$trans(row)}}</template>
        </el-table-column>
        <el-table-column fixed="right" :width="200" class-name="actions-cell">
          <template slot-scope="{row}">
            <base-button
              @click.stop="handleEdit(row)"
              size="small"
              prepend-icon="ri-pencil-line"
              :label="$t('general.button_label.edit')"
            />
            <base-button
              @click.stop="handleDelete  (row)"
              size="small"
              prependIcon='ri-delete-bin-2-line'
            />
          </template>
        </el-table-column>
      </el-table>
    </base-section>
  </div>
</template>

<script>
import { debounce } from 'lodash'

const target = 'label'

export default {
  name: 'LabelResource',
  props: ['totalPages', 'pagination'],
  data () {
    return {
      loading: false,
      tableData: [],
      active: true,
      translationLike: null
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    translationLike: debounce(function () {
      this.fetchData()
    }, 400),
    active: debounce(function () {
      this.fetchData()
    }, 400)

  },

  computed: {
    filter () {
      return {
        active: this.active,
        translationLike: this.translationLike || null,
        pagination: this.pagination
      }
    }
  },
  methods: {
    defaultOptions () {
      return {
        onSuccess: () => {
          this.fetchData()
        },
        ...this.finally()
      }
    },
    finally () {
      return {
        onFinally: () => {
          this.loading = false
        }
      }
    },
    resetPagination () {
      this.$emit('update:pagination', {
        page: 0,
        size: 50
      })
    },
    setData ({ content, totalPages }) {
      this.tableData = content
      this.$emit('update:totalPages', totalPages)
      this.loading = false
    },
    fetchData () {
      this.loading = true
      this.$api.manageResources.getByFilter(
        { target, form: this.filter },
        {
          onSuccess: (res) => {
            this.setData(res)
          },
          ...this.finally()
        }
      )
    },
    handleDelete ({ labelOspId }) {
      this.$api.manageResources.delete(
        { target, form: { ospId: labelOspId } },
        {
          ...this.defaultOptions(),
          successNotification: this.$t('resources.label_notification.delete')
        }
      )
    },
    handleEdit ({ labelOspId }) {
      this.$router.push({
        name: 'BackofficeAddOrEditLabel',
        params: {
          createOrEdit: 'edit'
        },
        query: {
          ospId: labelOspId
        }
      })
    }
  },
  mounted () {
    this.resetPagination()
    this.fetchData()
  }
}
</script>
