
<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <el-form :key="keyForm">
      <base-section :title="$t('resources.section.general')">
        <general-resource-section
          :data="form"
          :validations="$v"
          :isEditMode="isEditMode"
          :changeInfoExistLabel="handleSelectExistInfoLabel"
          :changeNameExistLabel="handleSelectExistNameLabel"
        />
         <add-label-inputs
          :form="form"
          :validations="$v"
          :changeExistLabelName="handleSelectExistNameLabel"
          :changeExistLabelInfo="handleSelectExistInfoLabel"
        />
      </base-section>
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import GeneralResourceSection from '@/components/Backoffice/ResourcesManagement/GeneralResourceSection'
import AddLabelInputs from '@/components/Backoffice/ResourcesManagement/AddLabelInputs'
import ResourcesManagement from '@/mixins/ResourcesManagement'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

const target = 'rentalcar'
export default {
  name: 'Service',
  components: {
    GeneralResourceSection,
    PageLayout,
    AddLabelInputs
  },
  mixins: [ResourcesManagement],
  validations () {
    return {
      form: {
        mrpOspId: {
          required
        },
        extId: {
          required
        },
        nameLabel: {
          defaultValue: {
            required
          }
        },
        mobilityMode: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/userCorporateAccount'
    }),
    getForm () {
      const form = {
        active: true,
        nameLabel: {
          ...this.form.nameLabel
        },

        extId: this.form.extId,

        mobilityMode: this.form.mobilityMode,
        mrpOspId: this.form.mrpOspId
      }
      if (this.form.infotextLabel.defaultValue !== null) {
        form.infotextLabel = { ...this.form.infotextLabel }
      }
      return form
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.rental')
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        this.$api.manageResources.create(
          { target, form: this.getForm },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.rental.notification.add')
          }
        )
      }
    },
    fetchRentalData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.setFormData(res)
              this.keyForm = res.ospId
            }
          }
        )
      }
    },
    updateRentalData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        this.$api.manageResources.update(
          { target, form: { data: this.getForm, ospId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t(
              'resources.rental.notification.update'
            )
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateRentalData()
      }
      return this.submit()
    },
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleRentalCarResource')
      }
      return this.$t('resources.view.add.titleRentalCarResource')
    }
  },
  mounted () {
    this.fetchAllMrp()
    this.fetchMobilityModes()
    this.fetchRentalData()
  }
}
</script>
