<template>
  <page-layout :title="getTitle()" :has-back-button="true" :emit-back="true" @back="goBack">
    <template #header-actions>
      <base-button type="primary" :loading="saving" :label="getButtonLabel" @click="getSubmit" />
    </template>
    <base-section :title="$t('resources.section.general')">
      <el-form :key="keyForm">
        <el-row>
          <el-col :span="12">
            <base-input
              required
              :label="$t('general.input.defaultValue_label')"
              v-model="form.defaultValue"
              :validations="$v.form.defaultValue"
            />
            <base-translations
              :label="$t('general.label.translations')"
              v-model="form.labelTranslations"
            />
          </el-col>
        </el-row>
      </el-form>
    </base-section>
  </page-layout>
</template>

<script>
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

import PageLayout from '@/layouts/PageLayout'
import { required } from 'vuelidate/lib/validators'

const target = 'label'

export default {
  name: 'Labels',
  components: {
    PageLayout
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      saving: false,
      ospId: null,
      keyForm: '',
      isEditMode: false,
      form: {
        defaultValue: null,
        labelTranslations: []
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.dirty = true
      }
    }
  },
  validations: {
    form: {
      defaultValue: {
        required
      }
    }
  },
  computed: {
    getForm () {
      return {
        defaultValue: this.form.defaultValue,
        labelTranslations: this.form.labelTranslations
      }
    },
    getButtonLabel () {
      if (this.isEditMode) {
        return this.$t('general.button_label.save_changes')
      }
      return this.$t('resources.buttons_label.labels')
    }
  },
  methods: {
    defaultOptions () {
      return {
        onSuccess: () => {
          this.dirty = false
          this.$router.back()
        },
        onFinally: () => {
          this.saving = false
        }
      }
    },
    getTitle () {
      if (this.isEditMode) {
        return this.$t('resources.view.edit.titleResourceLabel')
      }
      return this.$t('resources.view.add.titleResourceLabel')
    },
    fetchLabelData () {
      if (this.isEditMode) {
        this.$api.manageResources.get(
          { target, form: { ospId: this.ospId } },
          {
            onSuccess: (res) => {
              this.form.defaultValue = res.defaultValue
              this.form.labelTranslations = this.$fixTranslations(res.labelTranslations)
              this.keyForm = res.labelOspId
            }
          }
        )
      }
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.create({ target, form: this.getForm }, {
          ...this.defaultOptions(),
          successNotification: this.$t('resources.labels.notification.add')
        })
      }
    },
    updateLabelData () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        return this.$api.manageResources.update(
          { target, form: { ...this.getForm, labelOspId: this.ospId } },
          {
            ...this.defaultOptions(),
            successNotification: this.$t('resources.labels.notification.update')
          }
        )
      }
    },
    getSubmit () {
      if (this.isEditMode) {
        return this.updateLabelData()
      }
      return this.submit()
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.ospId = to.query.ospId
      vm.isEditMode = to.params.createOrEdit === 'edit'
    })
  },
  mounted () {
    this.fetchLabelData()
  }
}
</script>

<style>
</style>
